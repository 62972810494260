<template>
  <div>
    <div class="pane">
      <div class="title">消息中心</div>
      <div class="remarks">消息中心页面</div>
    </div>

    <div class="container">
      <a-tabs v-model="active">
        <div class="extra" slot="tabBarExtraContent">
          <a @click.prevent="read" style="color: #f00">全部已读</a>
        </div>
        <a-tab-pane :key="0" tab="未读">
          <List
            :type="0"
            :list="notReadList"
            :current="notReadCurrent"
            :total="notReadTotal"
          />
        </a-tab-pane>
        <a-tab-pane :key="1" tab="已读">
          <List
            :type="1"
            :list="hasReadList"
            :current="hasReadCurrent"
            :total="hasReadTotal"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { readAll } from "@/api/message";
import List from "./components/list.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  mixins: [watermark],

  components: {
    List,
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    ...mapState("message", [
      "hasReadList",
      "hasReadCurrent",
      "hasReadTotal",
      "notReadList",
      "notReadCurrent",
      "notReadTotal",
    ]),
  },

  methods: {
    ...mapMutations("message", ["setNotReadCurrent"]),
    ...mapActions("message", ["getMessageList"]),

    // 全部已读
    read() {
      // 视图在未读时点击才有用
      if (this.active === 0) {
        // 未读的设置为已读
        const idList = this.notReadList.map((item) => item.id);

        if (idList.length === 0) {
          return;
        }

        readAll({
          idList,
        }).then(() => {
          // 刷新数据
          if (this.notReadCurrent !== 1) {
            // 将分页器设置为1，list 组件会自动刷新
            this.setNotReadCurrent(1);
          } else {
            // 如果正好是第一页，设置分页器为1，不会触发 list 组件的 watch，手动刷新
            this.getMessageList({
              pageNum: 1,
              pageSize: 10,
              isRead: 0,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 32px;
  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
  }
  .remarks {
    margin-top: 8px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.container {
  background-color: #fff;
  padding: 0 12px 12px;
}
</style>