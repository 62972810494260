<template>
  <div v-if="list.length > 0">
    <div class="list" v-for="(item, index) in list" :key="index">
      <div class="left item" @click="onClick(item)">
        <div>
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
        <div class="time">
          {{ item.createAt }}
        </div>
      </div>
    </div>
    <div class="right">
      <a-pagination :total="total" :show-total="(total) => `共 ${total} 条记录`" :page-size="10" :current="current"
        @change="onChange" />
    </div>
  </div>
  <div v-else>
    <a-empty />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  props: {
    type: {
      type: Number,
    },
    list: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  watch: {
    current() {
      this.getList();
    },
  },

  mounted() {
    // 进入组件，状态同步
    if (this.type === 0) {
      this.setNotReadCurrent(1);
    }
    if (this.type === 1) {
      this.setHasReadCurrent(1);
    }
    this.getList();
  },

  methods: {
    ...mapActions("message", ["getMessageList", "readMessage"]),
    ...mapMutations("message", ["setHasReadCurrent", "setNotReadCurrent"]),

    getList() {
      this.loading = true;
      this.getMessageList({
        pageNum: this.current,
        pageSize: 10,
        isRead: this.type,
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(page) {
      if (this.type === 0) {
        this.setNotReadCurrent(page);
      } else if (this.type === 1) {
        this.setHasReadCurrent(page);
      }
    },

    onClick(item) {
      // 未读状态，设为已读
      if (item.isRead === 0) {
        this.readMessage({
          id: item.id,
        });
      }

      if (item.extra) {
        try {
          const extra = JSON.parse(item.extra);

          if (extra.taskId && extra.instanceId) {
            this.$router.push(
              `/oa/approval/detail?taskId=${extra.taskId}&instanceId=${extra.instanceId}`
            );
            return;
          }

        } catch (error) {
          alert("通知消息数据格式出错！");
        }
      }


      if (item.typeCode === "meet_book") {
        this.$router.push("/oa/meeting"); // 会议通知
      } else if (item.typeCode === "career_msg") {
        this.$router.push("/human-resources/recruit"); // 面试消息
      } else if (item.typeCode === "message_alert") {
        this.$router.push("/oa/supervise"); // 督办时间节点提醒
      } else if (item.typeCode === "account_receivable") {
        // 应收账款
        if (item.extra) {
          try {
            const extra = JSON.parse(item.extra);
            this.$router.push(
              `/finance/accounts-receivable/detail?id=${extra.id}`
            );
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$router.push("/finance/accounts-receivable");
        }
      } else if (item.typeCode === "allocate_person_message") {
        try {
          const extra = JSON.parse(item.extra);
          this.$router.push(
            "/produce/progress/major-allocate?id=" +
            extra.id +
            "&name=" +
            extra.name
          );
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else if (item.typeCode === "allocate_person_message_adjust") {
        try {
          const extra = JSON.parse(item.extra);
          this.$router.push(
            `/produce/progress/adjust-major-allocate?id=${extra.id}&progressId=${extra.progressId}&name=${extra.name}`
          );
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else if (item.typeCode === "invoice_change") {
        try {
          const extra = JSON.parse(item.extra);
          this.$router.push("/finance/invoice?code=" + extra.code);
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else if (item.typeCode === 'epc_prg_check') {
        this.$router.push("/epc/business-accounting");
      } else if (item.typeCode === 'inform_msg') {
            try {
          const extra = JSON.parse(item.extra);
          this.$router.push("/technical-quality/report/detail?id=" + extra.id);
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else if (item.typeCode === 'party_evaluation_mass') {
        try {
          const extra = JSON.parse(item.extra);
          window.open("https://portal.njszy.com/party/#/party/branchWork/partyMemberEvaluation/mass?branchId=" + extra.branchId + "&evaluationId=" + extra.evaluationId + "&massId=" + extra.massId + "&type=mass");
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else if (item.typeCode === 'party_evaluation_member') {
        try {
          const extra = JSON.parse(item.extra);
          window.open("https://portal.njszy.com/party/#/party/branchWork/partyMemberEvaluation/member?branchId=" + extra.branchId + "&evaluationId=" + extra.evaluationId + "&evaluationMemberId=" + extra.evaluationMemberId + "&type=member");
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else if (item.typeCode === 'party_evaluation_owner') {
        try {
          const extra = JSON.parse(item.extra);
          window.open("https://portal.njszy.com/party/#/party/branchWork/partyMemberEvaluation/owner?branchId=" + extra.branchId + "&evaluationUserId=" + extra.evaluationUserId + "&evaluationId=" + extra.evaluationId + "&type=user");
        } catch (error) {
          console.log(error);
          alert(error);
        }
      }else if (item.typeCode === 'party_evaluation_branch_user') {
        try {
          const extra = JSON.parse(item.extra);
          window.open("https://portal.njszy.com/party/#/party/branchWork/branchWorkEvaluation/branch?branchId=" + extra.branchId + "&evaluationId=" + extra.evaluationId + "&evaluationUserId=" + extra.evaluationUserId);
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else {
        this.$message.error("跳转链接尚未配置，请联系开发人员");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
  margin-bottom: 12px;
  cursor: pointer;

  .title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .time {
    margin-left: auto;
  }
}
</style>